<template>
	<div style="background-color:white">
		<div class="col-md-12" style="margin-top:20px">
			<div class="col-md-12">
				<h3 class="page-header">
					<i class="fa fa fa-users animated bounceInDown show-info"></i>
					Add Company
				</h3>
			</div>
		</div>
		<form @submit.prevent="onSubmit" @keydown="form.errors.clear($event.target.name)" class="p-8 lg:w-1/2 md:mx-auto">
			<div class="col-md-12 ">
				<div class="panel panel-cascade">
					<div class="panel-body">
						<div class="form-horizontal cascde-forms">

							<div class="form-group col-md-12 text-center">

								<div class="row mt-20">
									<div class="col-md-4">
										<label class="control-label">Add Brand<span
												style="color: rgba(255, 0, 0, 0.67)">*</span>
										</label>
									</div>
									<div class="col-md-4">
										<input type="text" class="form-control form-cascade-control input-small"
											v-model="form.value" required>
										<span class="invalid-feedback" role="alert">
											<strong></strong>
										</span>
									</div>
								</div>

								<div class="">
									<div class="" >
										<input type="button"
											style="background-color:#495b79!important;margin-top:23px;margin-left:35px;"
											value="Submit" @click="onSubmit()"
											class="btn bg-primary text-white chkcondition">
										<input type="button"
											style="background-color:#495b79!important;margin-top:23px;margin-left:35px;"
											value="Cancel" @click="cancel()" class="btn bg-primary text-white chkcondition">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>


		</form>
		<div class="cardbox1" style="margin-top:20px">
			<div class="col-md-12">
				<table class="table table-font">
					<thead>
						<tr>
							<th>S.No</th>
							<th>Company</th>
						</tr>
					</thead>
					<template v-for="item in brands">
						<tr v-for="(filter, index) in item.predefinedvalues" v-bind:key="filter.id">
							<td>{{ index + 1 }}</td>
							<td>{{ filter.value }}</td>
							<td><button type="button" class="badge btn-success btn-xs" @click="edit(filter)"><i
										class="fa fa-edit"></i></button></td>
						</tr>
					</template>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	components: {

	},
	data() {
		return {
			brands: '',
			form: new window.Form({
				id: 0,
				value: '',
				attribute_id: 2,
			})
		}
	},
	mounted() {
		if (this.edititem != null) {
			this.form = new window.Form(this.edititem);
		}
		this.refresh();
	},
	watch: {
		edititem: function () {
			if (this.edititem != null) {
				this.form = new window.Form(this.edititem);
			}
		}
	},
	computed: {

		...mapGetters([
			'edititem', 'categories', 'filters'
		]),
	},
	methods: {
		onSubmit: function () {
			this.$notify({
				text: 'Submitted',
				type: 'warning',
				duration: '80000',
				speed: '800',
				closeOnClick: false
			})
			this.$store.commit('assignloadingstatus', 1)
			this.form.attribute_id = 2
			this.form.submit(this, 'post', 'api/attribute/values/createupdate')
				.then(response => this.onSuccess(response))
				.catch(error => console.log(error));
		},
		onSuccess(data) {
			if (data.success == true) {
				this.$notify({
					text: 'Registered Successfully',
					type: 'success',
					duration: '80000',
					speed: '800',
					closeOnClick: false
				})
				this.refresh();
				this.$store.commit('assignloadingstatus', 0)
			} else {
				this.$notify({
					text: 'Already Exists',
					type: 'error',
					duration: '80000',
					speed: '800',
					closeOnClick: false
				})
				this.$store.commit('assignloadingstatus', 0)
			}
		},
		refresh() {
			this.$store.commit('assignloadingstatus', 1)
			let param = { id: 2 }
			window.axios.post('api/attributes/getallattributes', param)
				.then(response => {
					this.brands = response.data
					this.$store.commit('assignloadingstatus', 0)
				})
		},
		cancel() {
			this.$store.commit('assignloadingstatus', 0)
		},
		edit(item) {
			this.$notify({
				text: 'Edit Company',
				type: 'success',
				duration: '80000',
				speed: '800',
				closeOnClick: false
			})
			this.$store.commit("assignedititem", item)
		},

	}
}

</script>
